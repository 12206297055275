/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
#home {
  width: 166px;
  margin: 19px 7% 19px 0;
}
#head {
  width: 86%;
  margin: 120px 7% 0;
}
.phone {
  display: block;
}
.topservices {
  float: left;
  margin: 22px 0 22px 112px;
}
#pfad {
  width: 86%;
  margin-left: 7%;
  margin-right: 7%;
}
#specialmood {
  margin-top: 90px;
}
#multimood {
  margin-top: 80px;
}
.cb-layout2 #multimood,
.cb-layout3 #multimood,
.cb-layout4 #multimood,
.cb-layout5 #multimood {
  top: 90px;
}
.togglenavigation {
  left: 7%;
  top: 39px;
}
.cb-layout2 #content,
.cb-layout3 #content,
.cb-layout4 #content,
.cb-layout5 #content {
  margin-top: 90px;
}
#maincontent {
  margin-top: 80px;
}
.cb-layout2 #maincontent,
.cb-layout3 #maincontent,
.cb-layout4 #maincontent,
.cb-layout5 #maincontent {
  margin-top: 41.14583333%;
}
#footer {
  padding: 0 7%;
}
#view div.area div.fold div.ctrl {
  padding: 0 7%;
}
@media only screen and (max-width: 1023px) {
  #footnavi {
    float: left;
    width: 100%;
  }
  #social {
    width: 33.33333333%;
    margin-top: 42px;
  }
  .vcard {
    float: left;
    margin-left: 0;
    width: 66.66666667%;
  }
  .vcard .org,
  .vcard .adr,
  .vcard .street-address {
    display: block;
  }
  .vcard .postal-code {
    margin-left: 0;
  }
}
div.base div.unit {
  padding-top: 100px;
}
@media only screen and (max-width: 1023px) {
  #root #disp.srch div.body #cb-results {
    padding: 0 40px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 4%;
  margin-left: 4%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92%;
}
.area > .slim .tiny {
  width: 92%;
}
.cb-layout2 .main,
.cb-layout4 .main,
.cb-layout5 .main {
  width: 100%;
}
.cb-layout2 .main > .unit,
.cb-layout4 .main > .unit,
.cb-layout5 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout4 .main h2,
.cb-layout5 .main h2,
.cb-layout2 .main .foot,
.cb-layout4 .main .foot,
.cb-layout5 .main .foot {
  margin-right: 7%;
  margin-left: 7%;
}
.cb-layout2 .main .part,
.cb-layout4 .main .part,
.cb-layout5 .main .part,
.cb-layout2 .main > .grid table,
.cb-layout4 .main > .grid table,
.cb-layout5 .main > .grid table {
  margin-right: 7%;
  margin-left: 7%;
  width: 86%;
}
.cb-layout2 .main > .slim .part,
.cb-layout4 .main > .slim .part,
.cb-layout5 .main > .slim .part,
.cb-layout2 .main > .slim.grid table,
.cb-layout4 .main > .slim.grid table,
.cb-layout5 .main > .slim.grid table {
  width: 86%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 7%;
  margin-left: 7%;
}
.base .part,
.base > .grid table {
  margin-right: 7%;
  margin-left: 7%;
  width: 86%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 86%;
}
.cb-layout3 .base {
  width: 100%;
}
.cb-layout3 .base > .unit {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.cb-layout3 .base h2,
.cb-layout3 .base .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.cb-layout3 .base .part,
.cb-layout3 .base > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.cb-layout3 .base > .slim {
  width: 48%;
}
.cb-layout3 .base > .slim h2,
.cb-layout3 .base > .slim .foot,
.cb-layout3 .base > .slim .part,
.cb-layout3 .base > .slim.grid table {
  margin-right: 4.08333333%;
  margin-left: 4.08333333%;
}
.cb-layout3 .base > .slim .part,
.cb-layout3 .base > .slim.grid table {
  width: 91.83333333%;
}
.cb-layout3 .base > .slim .tiny {
  width: 91.83333333%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 7%;
  margin-left: 7%;
}
.south .part,
.south > .grid table {
  margin-right: 7%;
  margin-left: 7%;
  width: 86%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 86%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.wide .tall.auth.tile .text,
.wide .tall.auth.tile .password {
  width: 63%;
}
.wide .tall.payp.tile,
.wide .tall.payp.tile .papc {
  position: static;
}
.wide .tall.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.wide .tall.payp.tile input.numb {
  position: static;
  float: left;
}
.wide .tall.payp.tile .name {
  float: left;
  width: 60%;
}
@media only screen and (max-width: 1023px) {
  .main {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .cb-layout2 .main,
  .cb-layout4 .main,
  .cb-layout5 .main {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  div.base {
    margin-top: 40px;
  }
  .cb-layout3 div.base {
    width: 88%;
    margin-left: 6%;
  }
}
.topcontent .area.areaTen {
  width: calc(100% - 266px);
}
.topcontent .area.areaEleven {
  float: right;
  width: 242px;
}
.cb-layout2 #content div.main div.seam,
.cb-layout4 #content div.main div.seam,
.cb-layout5 #content div.main div.seam {
  padding: 0 22%;
}
.cb-layout1 div.base div.unit div.part,
.cb-layout1 div.base div.unit div.head h2,
.cb-layout2 div.base div.unit div.part,
.cb-layout2 div.base div.unit div.head h2,
.cb-layout4 div.base div.unit div.part,
.cb-layout4 div.base div.unit div.head h2,
.cb-layout5 div.base div.unit div.part,
.cb-layout5 div.base div.unit div.head h2 {
  margin-left: 7%;
  margin-right: 7%;
}
div.south div.unit div.part,
div.south div.unit div.head h2 {
  margin-left: 7%;
  margin-right: 7%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #3F7793;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #3F7793;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #305a6f;
}
.mese .same {
  background: none;
}
/******* module-filter-medium.less 2013-1-16 *******/
.cb-mobile-filter-items > .beta > span {
  width: 14.28571429%;
}
/*# sourceMappingURL=./screen-medium.css.map */